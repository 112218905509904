const state = {
    global: {}
}

const getters = {
    notification: (state) => state.global
}

const actions = {
    clearNotifications({ commit }) {
        commit('clearNotifications')
    }
}

const mutations = {
    errorGlobal: (state, msg) => {
        state.global.error = true
        state.global.msg = msg
    },
    successGlobal: (state, msg) => {
        state.global.success = true
        state.global.msg = msg
    },
    clearNotifications: (state) => {
        state.global = {}
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}