<template>

  <v-container class="full-height" :style="{backgroundColor: '#830017'}" >
    <v-row no-gutters justify="center">
      <v-col
      align-self="center"
        cols="10"
        md="3">
        <v-img :src="require('@/assets/rallylogga_vit.png')" contain></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col
          cols="12"
          md="4"
          align-self="center">
      <v-card class="elevation-12 rounded-lg mx-8 my-4">
          <v-toolbar dark>
            <v-toolbar-title>Logga in</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-text-field
                prepend-icon="mdi-at"
                name="email"
                v-model="email"
                label="Email"
                type="text"
                autocomplete="username"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                v-model="password"
                label="Kopplingskod"       
                autocomplete="current-password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="handleSubmit">Logga in</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col
      align-self="center"
        cols="10"
        md="3">
        <v-img :src="require('@/assets/anna_basic.png')" contain></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.full-height{
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}
</style>


<script>
import { mapGetters, mapActions } from "vuex"
import {notify} from '../utils/notification'

export default {
  name: 'Home',
  data: () => ({
    showPassword: false,
    email: '',
    password: '',
  }),
  methods: {
    ...mapActions(["loginUser", "clearNotifications", "isAuth"]),
    async handleSubmit() {
      await this.loginUser({email: this.email, password: this.password})

      // Notification
      const success = notify(this)
      if (success) {
        this.$router.push('/dashboard')
      }
    }
  },
  computed: mapGetters(["user", "loading", "notification"])
}
</script>
