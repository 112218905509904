import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

// Proxy the URL
import axios from 'axios'
// axios.defaults.baseURL = "http://localhost:3000"
axios.defaults.baseURL = "https://rallybot.utn.se"

// Toasts!
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
Vue.use(Toast)
// Cookies!
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
