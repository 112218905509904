/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

const state = {
  splekLoading: false
}

const getters = {
  splekLoading: (state) => state.splekLoading
}

const actions = {
  async sendSplekAnswer({ commit }, payload) {
    try {
      commit('setSplekLoading', true)
      return await axios.post(`/api/splek/answer/${payload.splekQuestionNumber}`,
      { answer: payload.answer }, getAuthHeader()).then(async () => {
        // Wait 1s to make sure the answer is uploaded
        await new Promise(resolve => setTimeout(resolve, 1000));
        commit('setSplekLoading', false)
        // From ./notification.js
        commit('successGlobal', 'Svar skickat!')
      })
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getAnsweredSplekQuestions({ commit }) {
    try {
      const { data } = await axios.get('/api/splek/answered', getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
    }
  },
  async getTeamAnsweredSplekQuestions({ commit }, teamId) {
    try {
      const { data } = await axios.get(`/api/splek/answered/${teamId}`, getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
    }
  }
}

const mutations = {
  setSplekLoading: (state, newLoading) => {
    state.splekLoading = newLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}