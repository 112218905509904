/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../../utils/tools'

const state = {}

const getters = {}

const actions = {
  async startTeam({ commit }, startPosition) {
    try {
      const { data } = await axios.get(`/api/teams/start/${startPosition}`, getAuthHeader())

      // Convert to local time zone
      const date = new Date(data)
      const timestampWithOffset = date.getTime()
      const dateWithOffset = new Date(timestampWithOffset)
      // Extract the time
      const time = dateWithOffset.toLocaleTimeString()

      // From ./notification.js
      commit('successGlobal', `Lag ${startPosition} startad kl. ${time}`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async finishTeam({ commit }, startPosition) {
    try {
      const { data } = await axios.get(`/api/teams/finish/${startPosition}`, getAuthHeader())

      // Convert to local time zone
      const date = new Date(data)
      const timestampWithOffset = date.getTime()
      const dateWithOffset = new Date(timestampWithOffset)
      // Extract the time
      const time = dateWithOffset.toLocaleTimeString()

      // From ./notification.js
      commit('successGlobal', `Lag ${startPosition} i mål kl. ${time}`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async checkInTeam({ commit }, startPosition) {
    try {
      const { data } = await axios.get(`/api/teams/checkin/${startPosition}`, getAuthHeader())

      // Convert to local time zone
      const date = new Date(data)
      const timestampWithOffset = date.getTime()
      const dateWithOffset = new Date(timestampWithOffset)
      // Extract the time
      const time = dateWithOffset.toLocaleTimeString()

      // From ./notification.js
      commit('successGlobal', `Lag ${startPosition} har checkat in kl. ${time}`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async reglementeTeam({ commit }, startPosition) {
    try {
      const { data } = await axios.get(`/api/teams/reglemente/${startPosition}`, getAuthHeader())
      // From ./notification.js
      if (data) { // Has handed it in 
        commit('successGlobal', `Lag ${startPosition} har lämnat in reglemente`)
      } else {
        commit('successGlobal', `Tog bort reglemente från lag ${startPosition}`)
      }
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async resetTeam({ commit }, startPosition) {
    try {
      await axios.get(`/api/teams/reset/${startPosition}`, getAuthHeader())
      commit('successGlobal', `Lag ${startPosition} har återställts`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async diskaTeam({ commit }, startPosition) {
    try {
      const { data } = await axios.get(`/api/teams/diska/${startPosition}`, getAuthHeader())
      console.log(data)

      if (data) { 
        commit('successGlobal', `Lag ${startPosition} har diskats`)
      } else {
        commit('successGlobal', `Slutade diska lag ${startPosition}`)
      }
    } catch (error) {

      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}