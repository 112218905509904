/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

const state = {
  loading: false
}

const actions = {
  async unlockStation({ commit }, payload) {
    try {
      const response = await axios.post(`/api/station/unlock/${payload.stationNumber}`,
      payload, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', 'Station öppnad, lycka till!')
      return response.data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getRebus({ commit }, stationNumber) {
    try {
      const response = await axios.get(`/api/station/rebus/${stationNumber}`, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', 'Station öppnad, lycka till!')
      return response.data.rebus
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getStationHelp({ commit }, stationNumber) {
    try {
      const help = await axios.get(`/api/station/help/${stationNumber}`, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', 'Hjälp given')
      return help
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  // Give the user all the help they've already unlocked for a station
  async getStationAvailableHelp({ commit }, stationNumber) {
    try {
      const availableHelp = await axios.get(`/api/station/availablehelp/${stationNumber}`, getAuthHeader())

      return availableHelp.data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  // For admin
  async getAllStations({ commit }) {
    try {
      // Same as doing const response = ... and then returning response.data
      const { data } = await axios.get('/api/station/all', getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async createStation({commit}, payload) {
    try {
      await axios.post('/api/station', payload, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', 'Station tillagd')
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async deleteOneStation({commit}, stationNumber) {
    try {
      await axios.delete(`/api/station/crud/${stationNumber}`, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', `Station ${stationNumber} raderad`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async updateOneStation({commit}, payload) {
    try {
      await axios.patch(`/api/station/crud/${payload.s_number}`, payload, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', `Station ${payload.s_number} uppdaterad`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  }
}

export default {
  state,
  actions
}