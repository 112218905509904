/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

const state = {
  trickyLoading: false
}

const getters = {
  trickyLoading: (state) => state.trickyLoading
}

const actions = {
  async sendTrickyQuestionAnswer({ commit }, payload) {
    try {
      commit('setTrickyLoading', true)

      return await axios.post(`/api/tricky/answer/${payload.trickyQuestionNumber}`, { answer: payload.answer }, getAuthHeader()).then(async () => {
        // Wait 1s to make sure the answer is uploaded
        await new Promise(resolve => setTimeout(resolve, 1000));
        commit('setTrickyLoading', false)
        // From ./notification.js
        commit('successGlobal', 'Svar skickat!')
      })
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      // console.log(error)
    }
  },
  async getAnsweredTrickyQuestions({ commit }) {
    try {
      const { data } = await axios.get('/api/tricky/answered', getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  // FOR ADMIN
  // For admin
  async getAllTrickyQuestions({ commit }) {
    try {
      // Same as doing const response = ... and then returning response.data
      const { data } = await axios.get('/api/tricky/all', getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      // console.log(error)
    }
  },
  async getTeamAnsweredTrickyQuestions({ commit }, t_id) {
    try {
      const { data } = await axios.get(`/api/tricky/answered/${t_id}`, getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      // console.log(error)
    }
  },
  async getAllTrickyAnswers({ commit }) {
    try {
      const { data } = await axios.get('/api/tricky/allAnswers', getAuthHeader())
      
      return data
    } catch (error) {
      commit('errorGlobal', error.response.data.message)
    }
  },
  async createTrickyQuestion({commit}, payload) {
    try {
      await axios.post('/api/tricky', payload, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', 'Kluring tillagd')
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      // console.log(error)
    }
  },
  async deleteTrickyQuestion({commit}, q_id) {
    try {
      await axios.delete(`/api/tricky/crud/${q_id}`, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', `Kluring ${q_id} raderad`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      // console.log(error)
    }
  },
  async updateTrickyQuestion({commit}, payload) {
    try {
      await axios.patch(`/api/tricky/crud/${payload.q_id}`, payload, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', `Kluring ${payload.s_number} uppdaterad`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      // console.log(error)
    }
  }
}

const mutations = {
  setTrickyLoading: (state, newLoading) => {
    state.trickyLoading = newLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}