import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#830017', // Rally-röd
                secondary: '#23afa1', // Turkos,
                success: '#51763c', // Grön
                accent: '#000000', // Becksvart
                error: '#671019' // Djupt röd
            }
        }
    }
});
