/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

const state = {
  missionLoading: false
}

const getters = {
  missionLoading: (state) => state.missionLoading
}

const actions = {
  async sendMission({ commit }, fileData) {
    try {
      if (fileData.get("filesize") > 85) {
        commit('errorGlobal', "Filen är för stor (max 85 MB). Komprimera eller maila filen till NätSäk istället.")
      } else {
        commit('setMissionLoading', true)
        await axios.post(`/api/mission/media`, fileData, getAuthHeader()).then(async () => {
          // Wait 1s to make sure the file is uploaded
          await new Promise(resolve => setTimeout(resolve, 2000));
          commit('setMissionLoading', false)
          // From ./notification.js
          commit('successGlobal', 'Svar skickat!')
        })
      }
    } catch (error) {
      commit('setMissionLoading', false)

      // Make the error message from MIME more detailed
      if (error.response.data.message === "File too large") {
        error.response.data.message = "Filen är för stor (max 85 MB). Komprimera eller maila filen till NätSäk istället."
      }
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getDoneMissions() {
    try {
      const response = await axios.get(`/api/mission/done`, getAuthHeader())
      return response.data
    } catch (error) {
      throw error
    }
  },
  async getTeamDoneMissions({ commit }, t_id) {
    try {
      const response = await axios.get(`/api/mission/done/${t_id}`, getAuthHeader())
      commit('setMissionLoading', false)
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const mutations = {
  setMissionLoading: (state, newLoading) => {
    state.missionLoading = newLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}