/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

const state = {
  haftigSubmissionLoading: false
}

const getters = {
  haftigSubmissionLoading: (state) => state.haftigSubmissionLoading
}

const actions = {
  async sendHaftigPlace({ commit }, fileData) {
    try {
      if (fileData.get("filesize") > 85) {
        commit('errorGlobal', "Filen är för stor (max 85 MB)")
      } else {
        commit('setHaftigSubmissionLoading', true)
        await axios.post(`/api/haftig/media`, fileData, getAuthHeader()).then(async () => {
          // Wait 2s to make sure the file is uploaded
          await new Promise(resolve => setTimeout(resolve, 2000));
          commit('setHaftigSubmissionLoading', false)
          // From ./notification.js
          commit('successGlobal', 'HÄFTig plats skickad!')
        })
      }
    } catch (error) {
      commit('setHaftigSubmissionLoading', false)
      // Make the error message from MIME more detailed
      if (error.response.data.message === "File too large") {
        error.response.data.message = "Filen är för stor (max 85 MB)"
      }
      commit('errorGlobal', error.response.data.message)
    }
  },
  async getDoneHaftigaPlaces() {
    try {
      const response = await axios.get(`/api/haftig/done/`, getAuthHeader())
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  async getTeamDoneHaftigaPlaces({commit}, t_id) {
    try {
      const response = await axios.get(`/api/haftig/done/${t_id}`, getAuthHeader())
      return response.data
    } catch (error) {
      commit('errorGlobal', error.response.data.message)
    }
  }
}

const mutations = {
  setHaftigSubmissionLoading: (state, newLoading) => {
    state.haftigSubmissionLoading = newLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}