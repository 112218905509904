/* Entry point file for the store */
import Vuex from 'vuex'
import Vue from 'vue'
import User from './modules/user'
import Notification from './modules/notification'
import Site from './modules/site'
import Stations from './modules/stations'
import Teams from './modules/team'
import TrickyQuestion from './modules/tricky_question'
import Mission from './modules/mission'
import Haftig from './modules/haftig'
import SplekAnswer from './modules/splek_answer'
import Steel from './modules/admin/steel'
import Lunch from './modules/admin/lunch'
import Correction from './modules/admin/correction'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
    modules: {
        user: User,
        notification: Notification,
        site: Site,
        stations: Stations,
        team: Teams,
        trickyQuestion: TrickyQuestion,
        mission: Mission,
        haftig: Haftig,
        splekAnswer: SplekAnswer,
        steel: Steel,
        lunch: Lunch,
        correction: Correction
    }
})