import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)


const beforeEnter = async (to, from, next) => {
  // The vuex store is not persistent on reload, therefore "hard check" auth
  await store.dispatch('isAuth')
  
  if (!store.getters.user.auth && to.name !== 'Home') {
    // If the user is not authenticated, redirect to the login page
    next('/')
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: beforeEnter
  },
  {
    path: '/dashboard',
    component: () => import('../views/Dashboard.vue'),
    
    children: [
      {
        path: '',
        name: 'DashboardHome',
        component: () => import('@/views/user/DashboardHome.vue'),
        beforeEnter: beforeEnter
      },
      {
        path: 'stations',
        name: 'Stations',
        component: () => import('@/views/user/Stations'),
        beforeEnter: beforeEnter
      },
      {
        path: 'missions',
        name: 'Missions',
        component: () => import('@/views/user/Missions'),
        beforeEnter: beforeEnter
      },
      {
        path: 'tricky',
        name: 'TrickyQuestion',
        component: () => import('@/views/user/TrickyQuestions'),
        beforeEnter: beforeEnter
      },
      {
        path: 'haftig',
        name: 'HaftigPlaces',
        component: () => import('@/views/user/HaftigPlaces'),
        beforeEnter: beforeEnter
      },
      {
        path: 'splek',
        name: 'SplekAnswers',
        component: () => import('@/views/user/SplekAnswers')
      },
      {
        path: 'lunch',
        name: 'Lunch',
        component: () => import('@/views/user/Lunch'),
        beforeEnter: beforeEnter
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('@/views/user/Contact'),
        beforeEnter: beforeEnter
      }
    ],
    beforeEnter: beforeEnter
  },
  {
    path: '/admin',
    component: () => import('@/views/Admin'),
    children: [
      {
        path: '',
        name: 'AdminHome',
        component: () => import('@/views/admin/AdminHome')
      },
      {
        path: 'progress',
        name: 'Lagstatus',
        component: () => import('@/views/admin/RallyProgress')
      },
      {
        path: 'steel',
        name: 'Steel',
        component: () => import('@/views/admin/Steel')
      },
      {
        path: 'lunch',
        name: 'LunchStation',
        component: () => import('@/views/admin/LunchStation')
      },
      {
        path: 'correction',
        name: 'Correction',
        component: () => import('@/views/admin/Correction')
      },
      {
        path: 'results',
        name: 'Results',
        component: () => import('@/views/admin/Results')
      },
      {
        path: 'trickyAnswers',
        name: 'TrickyQuestionAnswers',
        component: () => import('@/views/admin/TrickyQuestionAnswers')
      },
      // EDIT STUFF
      {
        path: 'stations',
        name: 'EditStations',
        component: () => import('@/views/admin/edit/Stations')
      },
      {
        path: 'teams',
        name: 'Teams',
        component: () => import('@/views/admin/edit/Teams')
      },
      // {
      //   path: 'stations/:id',
      //   name: 'StationSingle',
      //   component: () => import('@/views/admin/edit/StationSingle')
      // },
      {
        path: 'tricky',
        name: 'EditTrickyQuestion',
        component: () => import('@/views/admin/edit/TrickyQuestions')
      },
      {
        path: 'other',
        name: 'Other',
        component: () => import('@/views/admin/edit/Other')
      }
    ],
    // Route guard - If the account is not set as admin, redirect to login page
    beforeEnter: async(to, from, next) => {
      // The vuex store is not persistent on reload, therefore "hard check" admin
      await store.dispatch('isAuth')
      // When auth is checked, we get the user role in store
      const role = store.getters.user.data.u_role
      // TODO: It may be better to handle this in the back end (security concerns)
      if (!store.getters.user.auth && to.name !== 'Home') {
        next('/')
      } else {
        // If auth but not admin, it's a user, therefore go to dashboard
        if (role !== 'admin') {
          next('/dashboard')
        }
        next()
      }
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound')
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
