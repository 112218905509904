<template>
  <v-app>
    <!-- Show content when auth has been checked -->
    <v-main>
      <div v-if="loading" class="center">
        <vue-spinner />
      </div>
      <router-view v-else />
    </v-main>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.center {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
}
</style>

<script>
import { mapGetters, mapActions } from "vuex"
import Spinner from "vue-simple-spinner"

export default {
  components: {
    vueSpinner: Spinner
  },
  data: () => ({
    drawer: false,
    group: null,
    loading: true // TEST
  }),
  methods: {
    ...mapActions(['isAuth']),
    async checkAuth() {
      // Check if user if authorized before showing any content
      await this.isAuth()

      if (this.user.auth !== null) {
        this.loading = false
      }
    }
  },
  computed: mapGetters(["user"]),
  mounted() {
    this.checkAuth()
  },
  // watch: {
  //   $route (to, from) {
  //     console.log("To", to.name, "from", from.name);
  //   }
  // }
}
</script>
