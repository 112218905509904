/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../../utils/tools'

const actions = {
  async correctTeam({ commit }, payload) {
    try {
      const response = await axios.post('/api/teams/correct', payload, getAuthHeader())

      const totalTime = response.data.totalTime

      // Convert to hour, min and sec
      const h = Math.floor(totalTime / 3600)
      const m = Math.floor((totalTime - h*3600) / 60)
      const s = totalTime - h*3600 - m*60
      // From ./notification.js
      commit('successGlobal', `Lag ${payload.t_start_position} rättat. Totaltid: ${h}:${m}:${s}`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  }
}

export default {
  actions
}