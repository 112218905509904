// Snippet for showing a notification
// Returns true if success, otherwise false
import Toast from '../components/Toast'

const toastSettings = {
  position: "bottom-center",
  timeout: 3000
}

let content = {
  component: Toast,
  props: {
    msg: ''
  }
}

export const notify = (instance) => {
  /* Notifiction handling */
  if (instance.notification && instance.notification.error) {
    // Check docs for all the styling options
    content.props.msg = instance.notification.msg
    instance.$toast.error(content, toastSettings)
    instance.clearNotifications()
    return false
  }

  if (instance.notification && instance.notification.success) {
    content.props.msg = instance.notification.msg
    instance.$toast.success(content, toastSettings)
    instance.clearNotifications()
    return true
  }
}