/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../../utils/tools'

const state = {}

const getters = {}

const actions = {
  async lunchCheckIn({ commit }, startPosition) {
    try {
      const { data } = await axios.get(`/api/teams/lunchIn/${startPosition}`, getAuthHeader())

      // Convert to local time zone
      const date = new Date(data)
      const timestampWithOffset = date.getTime()
      const dateWithOffset = new Date(timestampWithOffset)
      // Extract the time
      const time = dateWithOffset.toLocaleTimeString()

      // From ./notification.js
      commit('successGlobal', `Lag ${startPosition} anlänt för lunch kl. ${time}`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async lunchCheckOut({ commit }, startPosition) {
    try {
      const { data } = await axios.get(`/api/teams/lunchOut/${startPosition}`, getAuthHeader())

      // Convert to local time zone
      const date = new Date(data)
      const timestampWithOffset = date.getTime()
      const dateWithOffset = new Date(timestampWithOffset)
      // Extract the time
      const time = dateWithOffset.toLocaleTimeString()

      // From ./notification.js
      commit('successGlobal', `Lag ${startPosition} klar med lunch kl. ${time}`)
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getLunchToken({ commit }) {
    try {
      const { data } = await axios.get('/api/teams/lunchToken', getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}