/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader, setTokenCookie, removeTokenCookie } from '../../utils/tools'

const state = {
  loading: false,
  data: {
    u_id: null,
    u_name: null,
    u_email: null,
    u_role: null
  },
  auth: null
}

const getters = {
  user: (state) => state,
  loading: (state) => state.loading,
  userData: (state) => state.data
}

const actions = {
  async loginUser({ commit }, payload) {
    try {
      // Keep track on the fact that we are waiting for the request
      commit('setLoading', true)

      const response = await axios.post('/api/auth/signin', {
        email: payload.email.toLowerCase().trim(),
        password: payload.password.trim()
      })
      // Now set the cookie manually, because of CORS error...
      setTokenCookie(response.data.token)

      commit('setUser', response.data.user)
      commit('setLoading', false)

      // From ./notification.js
      commit('successGlobal', 'Inloggad!')
    } catch (error) {
      commit('setLoading', false)
      
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      // console.log(error)
    }
  },
  signOut({ commit }) {
    removeTokenCookie()
    commit('resetUser')
  },
  async isAuth({ commit }) {
    try {
      // console.log(getAuthHeader());
      const response = await axios.get('/api/auth/isauth', getAuthHeader())
      // console.log(response.data)
      // Update user
      commit('updateUser', { data: response.data, auth: true })

      // TODO: Return is only necessary in Redux since we use commit here instead
      return { data: response.data, auth: true }
    } catch (error) {
      // console.log(error.response.data.message)
      // Update user
      commit('updateUser', { data: {}, auth: false })

      return { data: {}, auth: false }
    }
  }
}

const mutations = {
  setUser: (state, user) => {
    state.data = user
    state.auth = true
  },
  setLoading: (state, newLoading) => {
    state.loading = newLoading
  },
  updateUser: (state, payload) => {
    // If payload is empty (error), keep the state, otherwise replace it
    state.data = { ...state.data, ...payload.data },
    state.auth = payload.auth
  },
  resetUser: (state) => {
    // Back to default
    state.loading = false
    state.data = {
      u_id: null,
      u_name: null,
      u_email: null,
      u_role: null
    }
    state.auth = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}