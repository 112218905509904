/* eslint no-useless-catch: 0 */
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

const actions = {
  async getTeamUnlocks({ commit }) {
    try {
      const response = await axios.get('/api/teams/team/unlocks', getAuthHeader())

      return response.data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getTeam({ commit }) {
    try {
      const response = await axios.get('/api/teams/own', getAuthHeader())

      return response.data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  // For admin
  async getAllTeams({ commit }) {
    try {
      // Same as doing const response = ... and then returning response.data
      const { data } = await axios.get('/api/teams/all', getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getTeamByStartNumber({ commit }, startNumber) {
    try {
      const { data } = await axios.get(`/api/teams/${startNumber}`, getAuthHeader())

      return data
    } catch (error) {
      commit('errorGlobal', error.response.data.message)
    }
  },
  async createUsersAndTeams({ commit }, fileData) {
    try {
      await axios.post('/api/teams/uploadteams', fileData, getAuthHeader())

      // From ./notification.js
      commit('successGlobal', 'Lag uppladdat.')
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getAllResults({ commit }) {
    try {
      const { data } = await axios.get('/api/result/all', getAuthHeader())

      return data
    } catch (error) {
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
      console.log(error)
    }
  },
  async getTeamStationProgress({ commit }, t_id) {
    try {
      const response = await axios.get(`/api/teams/progress/${t_id}`, getAuthHeader())

      return response.data
    } catch (error) {
      commit('errorGlobal', error.response.data.message)
    }
  }
}

export default {
  actions
}