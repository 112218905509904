const state = {
    layout: ''
}

const getters = {
    site: (state) => state.layout
}

const actions = {}

const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations
}