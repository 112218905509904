
<template>
  <v-row class="notif-container">
    <v-col
      cols="3"
      class="notif-inner-container"
    >
      <v-img class="anna-notif-img" src="@/assets/anna_halv.svg"></v-img>
    </v-col>
    <v-col
      cols="9"
    >
      {{ msg }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'Toast',
  props: ['msg']
}
</script>
<style>

.notif-container{
  max-width: 75vw;
}
</style>